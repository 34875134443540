const actions = {
  FETCH_STORE_CATEGORIES: 'FETCH_STORE_CATEGORIES',
  RECEIVED_STORE_CATEGORIES: 'RECEIVED_STORE_CATEGORIES',
  FETCH_STORE_PRODUCTS: 'FETCH_STORE_PRODUCTS',
  RECEIVED_STORE_PRODUCTS: 'RECEIVED_STORE_PRODUCTS',
  FETCH_STORE_DESIGNERS: 'FETCH_STORE_DESIGNERS',
  RECEIVED_STORE_DESIGNERS: 'RECEIVED_STORE_DESIGNERS',
  FETCH_STORE_OCCASIONS: 'FETCH_STORE_OCCASIONS',
  RECEIVED_STORE_OCCASIONS: 'RECEIVED_STORE_OCCASIONS',
  FETCH_STORE_FORMALITIES: 'FETCH_STORE_FORMALITIES',
  RECEIVED_STORE_FORMALITIES: 'RECEIVED_STORE_FORMALITIES',
  FETCH_STORE_COLORS: 'FETCH_STORE_COLORS',
  RECEIVED_STORE_COLORS: 'RECEIVED_STORE_COLORS',
  RECEIVED_STORE_FILTERS: 'RECEIVED_STORE_FILTERS',
  FETCH_STORE_PRODUCT: 'FETCH_STORE_PRODUCT',
  RECEIVED_STORE_PRODUCT: 'RECEIVED_STORE_PRODUCT',
  FETCH_STORE_FABRICS: 'FETCH_STORE_FABRICS',
  RECEIVED_STORE_FABRICS: 'RECEIVED_STORE_FABRICS',
  FETCH_STORE_SIZES: 'FETCH_STORE_SIZES',
  RECEIVED_STORE_SIZES: 'RECEIVED_STORE_SIZES',
  FETCH_STORE_PLAN: 'FETCH_STORE_PLAN',
  RECEIVED_STORE_PLAN: 'RECEIVED_STORE_PLAN',
  FETCH_USER_INVOICES: 'FETCH_USER_INVOICES',
  RECEIVED_USER_INVOICES: 'RECEIVED_USER_INVOICES',
  USER_LOADING: 'USER_LOADING',
  FETCH_USER_SUBSCRIPTION: 'FETCH_USER_SUBSCRIPTION',
  RECEIVED_USER_SUBSCRIPTION: 'RECEIVED_USER_SUBSCRIPTION',
  UPDATE_USER_SUBSCRIPTIONS: 'UPDATE_USER_SUBSCRIPTIONS',
  CANCEL_USER_SUBSCRIPTION: 'CANCEL_USER_SUBSCRIPTION',
  ACTIVATE_USER_SUBSCRIPTION: 'ACTIVATE_USER_SUBSCRIPTION',
  FETCH_STORE_ORDERS: 'FETCH_STORE_ORDERS',
  FETCH_STORE_ORDERS_LIMIT: 'FETCH_STORE_ORDERS_LIMIT',
  RECEIVED_STORE_ORDERS: 'RECEIVED_STORE_ORDERS',
  RECEIVED_STORE_ORDERS_LIMIT: 'RECEIVED_STORE_ORDERS_LIMIT',
  FETCH_STORE_ORDER: 'FETCH_STORE_ORDER',
  RECEIVED_STORE_ORDER: 'RECEIVED_STORE_ORDER',
  STORE_LOADING: 'STORE_LOADING',
  FETCH_STORE_WISHLIST: 'FETCH_STORE_WISHLIST',
  RECEIVED_STORE_WISHLIST: 'RECEIVED_STORE_WISHLIST',
  REACTIVATE_USER_SUBSCRIPTION: "REACTIVATE_USER_SUBSCRIPTION",
  FETCH_USER_ORDER: 'FETCH_USER_ORDER',
  ADD_PRODUCT_REVIEW: 'ADD_PRODUCT_REVIEW',
  FETCH_USER_REVIEWS: "FETCH_USER_REVIEWS",
  RECEIVED_USER_REVIEWS: "RECEIVED_USER_REVIEWS",
  FETCH_PRODUCT_REVIEWS: "FETCH_PRODUCT_REVIEWS",
  RECEIVED_PRODUCT_REVIEWS: "RECEIVED_PRODUCT_REVIEWS",

  getProductReview: payload => ({
	type: actions.FETCH_PRODUCT_REVIEWS,
	payload
  }),
  getCategories: payload => ({
	type: actions.FETCH_STORE_CATEGORIES,
	payload
  }),
  getProducts: payload => ({
	type: actions.FETCH_STORE_PRODUCTS,
	payload
  }),
  getDesigners: payload => ({
	type: actions.FETCH_STORE_DESIGNERS,
	payload
  }),
  getOccasions: payload => ({
	type: actions.FETCH_STORE_OCCASIONS,
	payload
  }),
  getFormalities: payload => ({
	type: actions.FETCH_STORE_FORMALITIES,
	payload
  }),
  getColors: payload => ({
	type: actions.FETCH_STORE_COLORS,
	payload
  }),
  getFabrics: payload => ({
	type: actions.FETCH_STORE_FABRICS,
	payload
  }),
  getSizes: payload => ({
	type: actions.FETCH_STORE_SIZES,
	payload
  }),
  setFilters: payload => ({
	type: actions.RECEIVED_STORE_FILTERS,
	payload
  }),
  getProduct: payload => ({
	type: actions.FETCH_STORE_PRODUCT,
	payload
  }),
  loading: payload => ({
	type: actions.STORE_LOADING,
	payload
  }),
  getUserOrder: payload => ({
	type: actions.FETCH_USER_ORDER,
	payload
  }),
  getReviews: payload => ({
	type: actions.FETCH_USER_REVIEWS,
	payload
  }),
  submitReview: payload => ({
	type: actions.ADD_PRODUCT_REVIEW,
	payload
  }),
  cancelUserSubscription: payload => ({
	type: actions.CANCEL_USER_SUBSCRIPTION,
	payload
  }),
  reactivateMembership: payload => ({
	type: actions.REACTIVATE_USER_SUBSCRIPTION,
	payload
  }),
  activateUserSubscription: payload => ({
	type: actions.ACTIVATE_USER_SUBSCRIPTION,
	payload
  }),
  getOrders: payload => ({
	type: actions.FETCH_STORE_ORDERS,
	payload
  }),
  getOrdersLimit: payload => ({
	type: actions.FETCH_STORE_ORDERS_LIMIT,
	payload
  }),
  getOrder: payload => ({
	type: actions.FETCH_STORE_ORDER,
	payload
  }),
  getWishlist: payload => ({
	type: actions.FETCH_STORE_WISHLIST,
	payload
  }),
};
export default actions;
