const actions = {
  FETCH_ADMIN_PRODUCTS: 'FETCH_ADMIN_PRODUCTS',
  RECEIVED_ADMIN_PRODUCTS: 'RECEIVED_ADMIN_PRODUCTS',
  RECEIVED_ADMIN_PRODUCT: 'RECEIVED_ADMIN_PRODUCT',
  UPDATE_ADMIN_PRODUCT: 'UPDATE_ADMIN_PRODUCT',
  FETCH_ADMIN_DESIGNERS: 'FETCH_ADMIN_DESIGNERS',
  RECEIVED_ADMIN_DESIGNERS: 'RECEIVED_ADMIN_DESIGNERS',
  RECEIVED_ADMIN_FORMALITIES: 'RECEIVED_ADMIN_FORMALITIES',
  RECEIVED_ADMIN_OCCASIONS: 'RECEIVED_ADMIN_OCCASIONS',
  RECEIVED_ADMIN_FABRICS: 'RECEIVED_ADMIN_FABRICS',
  RECEIVED_ADMIN_COLORS: 'RECEIVED_ADMIN_COLORS',
  RECEIVED_ADMIN_SIZES: 'RECEIVED_ADMIN_SIZES',
  ADD_ADMIN_COLOR: 'ADD_ADMIN_COLOR',
  RECEIVED_ADMIN_COLOR: 'RECEIVED_ADMIN_COLOR',
  ADD_ADMIN_FABRIC: 'ADD_ADMIN_FABRIC',
  RECEIVED_ADMIN_FABRIC: 'RECEIVED_ADMIN_FABRIC',
  ADD_ADMIN_OCCASION: 'ADD_ADMIN_OCCASION',
  RECEIVED_ADMIN_OCCASION: 'RECEIVED_ADMIN_OCCASION',
  ADD_ADMIN_FORMALITY: 'ADD_ADMIN_FORMALITY',
  RECEIVED_ADMIN_FORMALITY: 'RECEIVED_ADMIN_FORMALITY',
  ADD_ADMIN_SIZE: 'ADD_ADMIN_SIZE',
  RECEIVED_ADMIN_SIZE: 'RECEIVED_ADMIN_SIZE',
  ADD_ADMIN_DESIGNER: 'ADD_ADMIN_DESIGNER',
  RECEIVED_ADMIN_DESIGNER: 'RECEIVED_ADMIN_DESIGNER',
  ADD_ADMIN_PRODUCT: 'ADD_ADMIN_PRODUCT',
  ADD_BULK_PRODUCTS: 'ADD_BULK_PRODUCTS',
  RECEIVED_MISSING_BULK: 'RECEIVED_MISSING_BULK',
  UPDATE_PRODUCT_IMAGE: 'UPDATE_PRODUCT_IMAGE',
  RECEIVED_ADMIN_CATEGORIES: 'RECEIVED_ADMIN_CATEGORIES',
  UPDATE_ADMIN_CATEGORIES: 'UPDATE_ADMIN_CATEGORIES',
  UPDATE_ADMIN_COLOR: 'UPDATE_ADMIN_COLOR',
  UPDATE_ADMIN_SIZE: 'UPDATE_ADMIN_SIZE',
  UPDATE_ADMIN_OCCASION: 'UPDATE_ADMIN_OCCASION',
  UPDATE_ADMIN_FORMALITY: 'UPDATE_ADMIN_FORMALITY',
  FETCH_ADMIN_USERS: 'FETCH_ADMIN_USERS',
  RECEIVED_ADMIN_USERS: 'RECEIVED_ADMIN_USERS',
  ADD_ADMIN_CATEGORY: 'ADD_ADMIN_CATEGORY',
  RECEIVED_ADMIN_CATEGORY: 'RECEIVED_ADMIN_CATEGORY',
  FETCH_ADMIN_USER: "FETCH_ADMIN_USER",
  RECEIVED_ADMIN_USER: 'RECEIVED_ADMIN_USER',
  UPDATE_ADMIN_USER: "UPDATE_ADMIN_USER",
  FETCH_ADMIN_ORDERS: 'FETCH_ADMIN_ORDERS',
  RECEIVED_ADMIN_ORDERS: 'RECEIVED_ADMIN_ORDERS',
  RECEIVED_ADMIN_ORDER: 'RECEIVED_ADMIN_ORDER',
  UPDATE_ADMIN_ORDER: 'UPDATE_ADMIN_ORDER',
  UPDATE_ADMIN_DESIGNER: 'UPDATE_ADMIN_DESIGNER',
  DELETE_ADMIN_DESIGNER: 'DELETE_ADMIN_DESIGNER',
  DELETE_ADMIN_PRODUCT: 'DELETE_ADMIN_PRODUCT',
  RECEIVED_ADMIN_USER_SUBSCRIPTIONS: 'RECEIVED_ADMIN_USER_SUBSCRIPTIONS',
  SEND_ADMIN_SHIPPING_CONFIRMATION_EMAIL: "SEND_ADMIN_SHIPPING_CONFIRMATION_EMAIL",
  DELETE_ADMIN_CATEGORY: "DELETE_ADMIN_CATEGORY",
  DELETE_ADMIN_FORMALITY: "DELETE_ADMIN_FORMALITY",
  DELETE_ADMIN_SIZE: "DELETE_ADMIN_SIZE",
  DELETE_ADMIN_COLOR: "DELETE_ADMIN_COLOR",
  DELETE_ADMIN_OCCASION: "DELETE_ADMIN_OCCASION",
  APP_SETTINGS: 'APP_SETTINGS',
  RECEIVED_APP_SETTINGS: "RECEIVED_APP_SETTINGS",
  UPDATE_ADMIN_APP_SETTINGS: "UPDATE_ADMIN_APP_SETTINGS",
  FETCH_ADMIN_COUPONS: "FETCH_ADMIN_COUPONS",
  FETCH_ADMIN_REVIEWS: "FETCH_ADMIN_REVIEWS",
  RECEIVED_ADMIN_REVIEWS: "RECEIVED_ADMIN_REVIEWS",
  FETCH_ADMIN_REVIEW: "FETCH_ADMIN_REVIEW",
  RECEIVED_ADMIN_REVIEW: "RECEIVED_ADMIN_REVIEW",
  UPDATE_ADMIN_REVIEW: "UPDATE_ADMIN_REVIEW",

  deleteOccasion: payload => ({
    type: actions.DELETE_ADMIN_OCCASION,
    payload
  }),
  addColor: payload => ({
    type: actions.ADD_ADMIN_COLOR,
    payload
  }),
  addFabric: payload => ({
    type: actions.ADD_ADMIN_FABRIC,
    payload
  }),
  addOccasion: payload => ({
    type: actions.ADD_ADMIN_OCCASION,
    payload
  }),
  addFormality: payload => ({
    type: actions.ADD_ADMIN_FORMALITY,
    payload
  }),
  addSize: payload => ({
    type: actions.ADD_ADMIN_SIZE,
    payload
  }),
  addDesigner: payload => ({
    type: actions.ADD_ADMIN_DESIGNER,
    payload
  }),
  addCategory: payload => ({
    type: actions.ADD_ADMIN_CATEGORY,
    payload
  }),
  addNewProduct: payload => ({
    type: actions.ADD_ADMIN_PRODUCT,
    payload
  }),
  addBulkProducts: payload => ({
    type: actions.ADD_BULK_PRODUCTS,
    payload
  }),
  deleteColor: payload => ({
    type: actions.DELETE_ADMIN_COLOR,
    payload
  }),
  deleteSize: payload => ({
    type: actions.DELETE_ADMIN_SIZE,
    payload
  }),
  deleteFormality: payload => ({
    type: actions.DELETE_ADMIN_FORMALITY,
    payload
  }),
  deleteDesigner: payload => ({
    type: actions.DELETE_ADMIN_DESIGNER,
    payload
  }),
  deleteCategories: payload => ({
    type: actions.DELETE_ADMIN_CATEGORY,
    payload
  }),
  deleteProduct: payload => ({
    type: actions.DELETE_ADMIN_PRODUCT,
    payload
  }),
  getAppSettings: () => ({
    type: actions.APP_SETTINGS,
  }),
  getUsers: payload => ({
    type: actions.FETCH_ADMIN_USERS,
    payload
  }),
  getUser: payload => ({
    type: actions.FETCH_ADMIN_USER,
    payload
  }),
  getOrders: payload => ({
    type: actions.FETCH_ADMIN_ORDERS,
    payload
  }),
  getReviews: payload => ({
    type: actions.FETCH_ADMIN_REVIEWS,
    payload
  }),
  getProducts: payload => ({
    type: actions.FETCH_ADMIN_PRODUCTS,
    payload
  }),
  getDesigners: payload => ({
    type: actions.FETCH_ADMIN_DESIGNERS,
    payload
  }),
  getAdminReview: payload => ({
    type: actions.FETCH_ADMIN_REVIEW,
    payload
  }),
  updateProductImage: payload => ({
    type: actions.UPDATE_PRODUCT_IMAGE,
    payload
  }),
  updateCategory: payload => ({
    type: actions.UPDATE_ADMIN_CATEGORIES,
    payload
  }),
  updateDesigner: payload => ({
    type: actions.UPDATE_ADMIN_DESIGNER,
    payload
  }),
  updateColor: payload => ({
    type: actions.UPDATE_ADMIN_COLOR,
    payload
  }),
  updateSize: payload => ({
    type: actions.UPDATE_ADMIN_SIZE,
    payload
  }),
  updateOccasion: payload => ({
    type: actions.UPDATE_ADMIN_OCCASION,
    payload
  }),
  updateFormality: payload => ({
    type: actions.UPDATE_ADMIN_FORMALITY,
    payload
  }),
  updateProduct: payload => ({
    type: actions.UPDATE_ADMIN_PRODUCT,
    payload
  }),
  updateAdminUser: payload => ({
    type: actions.UPDATE_ADMIN_USER,
    payload
  }),
  updateOrder: payload => ({
    type: actions.UPDATE_ADMIN_ORDER,
    payload
  }),
  updateAppSettings: payload => ({
    type: actions.UPDATE_ADMIN_APP_SETTINGS,
    payload
  }),
  saveAdminReview: payload => ({
    type: actions.UPDATE_ADMIN_REVIEW,
    payload
  }),
  sendShippingEmail: payload => ({
    type: actions.SEND_ADMIN_SHIPPING_CONFIRMATION_EMAIL,
    payload
  }),
};
export default actions;
