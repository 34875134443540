import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import { addLocaleData } from 'react-intl';

const AppLocale = {
  en: en,
  fr: fr
};
addLocaleData([...en, ...fr]);

export default AppLocale;
