import styled from 'styled-components';
import { palette } from 'styled-theme';

const AppHolder = styled.div`
  -webkit-overflow-scrolling: touch;
  .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 16px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .trigger:hover {
    color: ${palette('primary', 0)};
  }

  .ant-layout-sider-collapsed .anticon {
    font-size: 16px;
  }

  .ant-layout-sider-collapsed .nav-text {
    display: none;
  }

  .ant-layout {
    background: ${palette('secondary', 1)};

    &.isoContentMainLayout {
      overflow: auto;
      overflow-x: hidden;
      @media only screen and (min-width: 768px) and (max-width: 1220px) {
        width: calc(100% - 80px);
        flex-shrink: 0;
      }

      @media only screen and (max-width: 767px) {
        width: 100%;
        flex-shrink: 0;
      }
    }
  }

  .isoLayoutContent {
    width: 100%;
    padding: 35px;
    background-color: #ffffff;
    border: 1px solid ${palette('border', 0)};
    height: 100%;
  }
 
  .isomorphicLayout {
    width: calc(100% - 240px);
    flex-shrink: 0;
    overflow-x: hidden !important;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }

    @media only screen and (min-width: 768px) and (max-width: 1220px) {
      width: calc(100% - 80px);
      width: 100%;
    }
  }

  .ant-layout-footer {
    font-size: 13px;
    @media (max-width: 767px) {
      padding: 10px 20px;
    }
  }
  .page-entrytitle{
        margin-bottom:35px;
    }
  .btr-editor{
    background:#fff;
    min-height:200px;
    padding:20px;
    }

.back-wrapper{
        border-bottom: 1px solid rgba(0,0,0,0.1);
        height:50px;
        margin-bottom:25px;
        a{
            line-height: 50px;
            i{
                    color: #000;
            }
        }
    }
 .loading-wrapper{
        width:100%;
        margin-top:0;
        .ant-spin i{
            font-size:100px!important;
                height: 100px;
            width: 100px;;
        }
    }
  .admin-toolbar{
    text-align:right;
    padding:1rem;
        .ant-btn{
            margin-left:15px;
        }
   }

  ${'' /* button {
    border-radius: 0;
  } */};
`;

export default AppHolder;
