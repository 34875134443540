import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import shopSaga from './admin/shop/saga';
import ecommerceSaga from './ecommerce/saga';
import storeSagas from './store/saga';

export default function* rootSaga() {
  yield all([
    authSagas(),
    shopSaga(),
    ecommerceSaga(),
    storeSagas(),
  ]);
}
