export default class SearchUtil {
	searchDesigner(designer, prop, designers) {
		for (let i = 0; i < designers.length; i++) {
			if (designers[i][prop].toLowerCase() === designer.toLowerCase()) {
				return designers[i];
			}
		}
	}

	searchCategory(category, prop, categories) {
		for (let i = 0; i < categories.length; i++) {
			if (categories[i][prop].toLowerCase() === category.toLowerCase()) {
				return categories[i];
			}
		}
	}

	searchColor(colorToSearch, name, colorsList) {
		for (let i = 0; i < colorsList.length; i++) {
			if (colorsList[i][name].toLowerCase() === colorToSearch.toLowerCase()) {
				return colorsList[i];
			}
		}
	}

	searchOccasion(occasionToFind, name, occasionsArr) {
		for (let i = 0; i < occasionsArr.length; i++) {
			if (occasionsArr[i][name].toLowerCase() === occasionToFind.toLowerCase()) {
				return occasionsArr[i];
			}
		}
	}

	searchFormality(search, name, formalitiesList) {
		for (let i = 0; i < formalitiesList.length; i++) {
			if (formalitiesList[i][name].toLowerCase() === search.toLowerCase()) {
				return formalitiesList[i];
			}
		}
	}
}