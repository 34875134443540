import React from 'react';
import {Route, Redirect, Switch} from 'react-router-dom';
import {ConnectedRouter} from 'connected-react-router';
import {connect} from 'react-redux';
import AdminApp from './containers/AdminApp/App';
import App from './containers/App/App';
import Auth0 from './helpers/auth0';

const RestrictedRoute = ({component: Component, isLoggedIn, ...rest}) => (
	<Route
		{...rest}
		render={props =>
			isLoggedIn ? (
				<Component {...props} />
			) : (
				<Redirect
					to={{
					  pathname: '/signin',
					  state: {from: props.location},
					}}
				/>
			)
		}
	/>
);
const PublicRoutes = ({history, isLoggedIn}) => {
  return <ConnectedRouter history={history}>
	<Switch>
	  <RestrictedRoute path={'/admin'} component={AdminApp} isLoggedIn={isLoggedIn}/>
	  <Route path={'/'} component={App}/>
	  <Route path="/auth0loginCallback" render={props => {
		Auth0.handleAuthentication(props);
	  }}/>
	</Switch>
  </ConnectedRouter>;
};

export default connect(state => ({
  isLoggedIn: state.Auth.idToken !== null,
}))(PublicRoutes);
