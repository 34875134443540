export const addToCart = (product) => {
  let products = [];
  if (localStorage.getItem('buySellproducts')) {
    products = JSON.parse(localStorage.getItem('buySellproducts'));
  }
  let filtered = products.filter(p => p.id === product.id);
  if (filtered.length === 1) {
    window.alert("Already added to cart!");
    return false;
  } else {
    products.push(product);
    localStorage.setItem('buySellproducts', JSON.stringify(products));
    return true;
  }
}

export const getCartItems = () => {
  let products = [];
  if (localStorage.getItem('buySellproducts')) {
    products = JSON.parse(localStorage.getItem('buySellproducts'));
  }
  return products;
}

export const isAdded = (id) => {
  let products = [];
  if (localStorage.getItem('buySellproducts')) {
    products = JSON.parse(localStorage.getItem('buySellproducts'));
  }
  let filtered = products.filter(p => p.id === id);
  return filtered.length > 0;
}

export const removeFromCart = (id) => {
  let storageProducts = JSON.parse(localStorage.getItem('buySellproducts'));
  let products = storageProducts.filter(product => product.id !== id);
  localStorage.setItem('buySellproducts', JSON.stringify(products));
}

export const clearCart = () => {
  localStorage.setItem("buySellproducts", JSON.stringify([]));
}

export const itemConditionDefinition = (condition) => {
  if (condition === "Brand new") {
    return "New and/or with tags, unused, and unworn, and/or in the original packaging (such as the original box or bag, dust cover, authenticity card)";
  } else if (condition === "New") {
    return "Unused and unworn.";
  } else if (condition === "Like new") {
    return "Used a few times by the previous owner, no imperfections, scratches, small cracks.";
  } else if (condition === "Excellent") {
    return "Few signs of wear, few small scratches, small cracks, minor marks.";
  } else if (condition === "Good") {
    return "Some signs of wear. Few minor wrinkles, few minor stains and scratches.";
  } else {
    return "Some obvious damages and/or smell (perfume, cigarette smoke, etc)";
  }
}