import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import Loadable from "react-loadable";
import { LOAD_TEXT } from "../../settings";
import { Spin } from "antd";

const shopLoadable = {
	shop: Loadable({
		loader: () => import('../Ecommerce/shop'),
		loading() {
			return LOAD_TEXT;
		}
	})
};

const routes = [
	{
		path: 'cart',
		component: Loadable({
			loader: () => import('../Ecommerce/cart'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'clients-cart',
		component: Loadable({
			loader: () => import('../Ecommerce/cart/clients-cart'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'checkout',
		component: Loadable({
			loader: () => import('../Ecommerce/checkout'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: '',
		component: Loadable({
			loader: () => import('../Page/home'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'how-it-works',
		component: Loadable({
			loader: () => import('../Page/howItWorks'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'membership',
		component: Loadable({
			loader: () => import('../../components/Membership'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'about',
		component: Loadable({
			loader: () => import('../Page/about'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'designers',
		component: Loadable({
			loader: () => import('../Page/allDesigners'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'faq',
		component: Loadable({
			loader: () => import('../Page/faq'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'consignment',
		component: Loadable({
			loader: () => import('../Page/consignment'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'terms-conditions',
		component: Loadable({
			loader: () => import('../Page/terms-conditions'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'rental-agreement',
		component: Loadable({
			loader: () => import('../Page/rental-agreement'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'contact',
		component: Loadable({
			loader: () => import('../Page/contact'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'newsletter',
		component: Loadable({
			loader: () => import('../Page/newsletter'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'signup',
		component: Loadable({
			loader: () => import('../../components/auth/signup'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'signin',
		component: Loadable({
			loader: () => import('../../components/auth/signin'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'shipping-returns',
		component: Loadable({
			loader: () => import('../Page/shipping-returns'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'successful-purchase',
		component: Loadable({
			loader: () => import('../Page/successfulPurchase'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'gift-coupons',
		component: Loadable({
			loader: () => import('../Page/gift-coupons'),
			loading() {
				return LOAD_TEXT;
			}
		})
	}
];

const clientAccounts = [
	{
		path: 'account',
		component: Loadable({
			loader: () => import('../Ecommerce/account/account'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'account/orders/:orderId',
		component: Loadable({
			loader: () => import('../Ecommerce/account/userOrderDetailed'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'account/review/:productId/:orderId',
		component: Loadable({
			loader: () => import('../Ecommerce/account/userReview'),
			loading() {
				return LOAD_TEXT;
			}
		})
	}
];

const buySellPages = [
	{
		path: 'how-to-sell',
		component: Loadable({
			loader: () => import('../Page/how-to-sell'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'list-item',
		component: Loadable({
			loader: () => import('../BuySell/list-item'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell',
		component: Loadable({
			loader: () => import('../Ecommerce/shop/buy-sell'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell/clothing',
		component: Loadable({
			loader: () => import('../Ecommerce/shop/buy-sell'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell/shoes',
		component: Loadable({
			loader: () => import('../Ecommerce/shop/buy-sell'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell/bags',
		component: Loadable({
			loader: () => import('../Ecommerce/shop/buy-sell'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell/accessories',
		component: Loadable({
			loader: () => import('../Ecommerce/shop/buy-sell'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell/:categoryId/:productId',
		component: Loadable({
			loader: () => import('../BuySell/buy-sell-product'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'buy-sell-checkout',
		component: Loadable({
			loader: () => import('../BuySell/buy-sell-checkout'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell-order/:orderId',
		component: Loadable({
			loader: () => import('../BuySell/buy-sell-order'),
			loading() {
				return <Spin />;
			}
		})
	},
	{
		path: 'buy-sell-cart',
		component: Loadable({
			loader: () => import('../Ecommerce/cart/buy-sell-shopping-bag'),
			loading() {
				return <Spin />;
			}
		})
	},
];

const shopPages = [
	{
		path: 'shop',
		component: shopLoadable.shop
	},
	{
		path: 'shop/:shopPath',
		component: shopLoadable.shop
	},
	{
		path: 'shop/categories/:categoryName',
		component: shopLoadable.shop
	},
	{
		path: 'shop/dresses/:dressLength',
		component: shopLoadable.shop
	},
	{
		path: 'shop/occasions/:occasionName',
		component: shopLoadable.shop
	},
	{
		path: 'shop/designers/:designerName',
		component: shopLoadable.shop
	},
	{
		path: 'shop/accessories/:accessoryName',
		component: shopLoadable.shop
	},
	{
		path: 'shop/products/:productName?/:productId',
		component: Loadable({
			loader: () => import('../Ecommerce/product/index'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
];

const blogRoutes = [
	{
		path: 'blog',
		component: Loadable({
			loader: () => import('../Page/blog'),
			loading() {
				return LOAD_TEXT;
			}
		})
	},
	{
		path: 'blog/:blogSlug',
		component: Loadable({
			loader: () => import('../Page/blog/index'),
			loading() {
				return LOAD_TEXT;
			}
		})
	}
];

const allRoutes_1 = routes.concat(clientAccounts);
const allRoutes_2 = allRoutes_1.concat(buySellPages);
const allRoutes_3 = allRoutes_2.concat(shopPages);
const allRoutes_4 = allRoutes_3.concat(blogRoutes);

class AppRouter extends Component {
	render() {
		const { style } = this.props;
		return (
			<div style={style}>
				{allRoutes_4.map(singleRoute => {
					const { path, exact, ...otherProps } = singleRoute;
					return (
						<Route
							exact={exact !== false}
							key={singleRoute.path}
							path={`/${singleRoute.path}`}
							{...otherProps}
						/>
					);
				})}
			</div>
		);
	}
}

export default AppRouter;
