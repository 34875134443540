import React from "react";
import { connect } from "react-redux";
import { Row, Col, Card } from "antd";
import Logo from '../../image/logo-btr.png';
import authAction from '../../redux/auth/actions';
import appActions from "../../redux/app/actions";
import styled from 'styled-components';
import { FormattedMessage, IntlProvider } from "react-intl";
import localeData from "../../locales/data.json";
import { LangContext } from "../../langContext";

const { changeLoginPopover } = appActions;
const SOCIAL_IMG = 'https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/social_media%2Fcss_sprites.0869e5a6a.png?alt=media&token=4697cc8b-2b70-4057-a49e-dcb2593dfabe';
const { logout } = authAction;
const { Meta } = Card;

class FooterApp extends React.PureComponent {
	static contextType = LangContext;
	constructor(props) {
		super(props);
		this.state = {
			current: '1',
			mode: 'inline',
			locale: "en"
		};
		this.handleClick = this.handleClick.bind(this);
		this.onClickNotLoggedIn = this.onClickNotLoggedIn.bind(this);
	}

	handleClick = e => this.setState({ current: e.key });

	onClickNotLoggedIn() {
		this.props.changeLoginPopover();
	}

	render() {
		let lang = this.context[0];
		const { user } = this.props;
		const accountLinks = () => {
			return (
        <ul style={{ paddingLeft: 0 }}>
          <li>
            {user !== null ? (
              <a href="/account?menu=orders">
                <FormattedMessage id="Menu.My.Orders" defaultMessage="Orders" />
              </a>
            ) : (
              <a href="#orders" onClick={this.onClickNotLoggedIn}>
                <FormattedMessage id="Menu.My.Orders" defaultMessage="Orders" />
              </a>
            )}
          </li>
          <li>
            {user !== null ? (
              <a href="/account?menu=wishlist">
								<FormattedMessage id="Account.Wishlist" defaultMessage="Wish list" />
							</a>
            ) : (
              <a href="#wishlist" onClick={this.onClickNotLoggedIn}>
                <FormattedMessage id="Account.Wishlist" defaultMessage="Wish list" />
              </a>
            )}
          </li>
          <li>
            {user !== null ? (
              <a href="/account?menu=addresses">
								<FormattedMessage id="Text.Shipping.Address" defaultMessage="Shipping address" />
							</a>
            ) : (
              <a href="#addresses" onClick={this.onClickNotLoggedIn}>
                <FormattedMessage id="Text.Shipping.Address" defaultMessage="Shipping address" />
              </a>
            )}
          </li>
          <li>
            {user !== null ? (
              <a href="#logout" onClick={() => this.props.logout()}>
                <FormattedMessage id="Menu.Logout" defaultMessage="Logout" />
              </a>
            ) : (
              <a href="#sign-in" onClick={this.onClickNotLoggedIn}>
                <FormattedMessage id="Menu.Sign.in" defaultMessage="Sign In" />
              </a>
            )}
          </li>
        </ul>
      );
		};
		return (
      <IntlProvider
        locale={lang}
        messages={localeData[lang]}
        defaultLocale="en"
      >
          <div className={`footer-main ${this.props.view === "DesktopView" ? ' desktopview' : ' mobileview'}`}>
            <Row type="flex" justify="center">
              <Col  className="footer-col" md={8}>
                <Card
                  cover={
                    <img className="logo-width" alt="footer-logo" src={Logo} />
                  }
                >
                  <Meta description={<FormattedMessage id="About.Us" />} />
                </Card>
              </Col>
              <Col className="footer-col" md={4}>
                <h5>
                  <FormattedMessage
                    id="Menu.Get.Help"
                    defaultMessage="Get Help"
                  />
                </h5>
                <ul style={{ paddingLeft: 0 }}>
                  <li>
                    <a href="/how-it-works" title="how it works">
                      <FormattedMessage
                        id="HowItWorks.Menu"
                        defaultMessage="How it works"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/faq">FAQs</a>
                  </li>
                  <li>
                    <a href="/terms-conditions">
                      <FormattedMessage
                        id="Menu.Terms.Conditions"
                        defaultMessage="Terms & Conditions"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/consignment">
                      <FormattedMessage
                        id="Menu.Consignment"
                        defaultMessage="Consignment"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/rental-agreement">
                      <FormattedMessage
                        id="Menu.Rental.Agreement"
                        defaultMessage="Rental Agreement"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/contact">
                      <FormattedMessage
                        id="Menu.Contact.Us"
                        defaultMessage="Contact Us"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/shipping-returns">
                      <FormattedMessage
                        id="Menu.Shipping.Returns"
                        defaultMessage="Shipping & Returns"
                      />
                    </a>
                  </li>
                </ul>
              </Col>
              <Col className="footer-col" md={4}>
                <h5>
                  <FormattedMessage
                    id="Menu.Account"
                    defaultMessage="Account"
                  />
                </h5>
                {accountLinks()}
              </Col>
              <Col className="footer-col" md={4}>
                <h5>
                  <FormattedMessage
                    id="Text.SellOnBTR"
                    defaultMessage="Sell on BTR"
                  />
                </h5>
                <ul style={{ paddingLeft: 0 }}>
                  <li>
                    <a href="/how-to-sell">
                      <FormattedMessage
                        id="Text.HowSellingWorks"
                        defaultMessage="How selling works"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/list-item">
                      <FormattedMessage
                        id="Text.StartSelling"
                        defaultMessage="Start selling"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="/buy-sell">
                      <FormattedMessage
                        id="Text.ShopNow"
                        defaultMessage="Shop Now"
                      />
                    </a>
                  </li>
                </ul>
              </Col>
              <Col className="footer-col" md={4}>
                <h5>
                  <FormattedMessage
                    id="Text.FollowUs"
                    defaultMessage="Follow Us"
                  />
                </h5>
                <FollowUs>
                  <div className="mainFollowUs">
                    <div className="mainFollowUsItem">
                      <a
                        href="https://www.facebook.com/dressbeyondtherunway/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="social_facebook"
                          style={{
                            background: `url(${SOCIAL_IMG}) -10px -10px`,
                          }}
                        >
                          &nbsp;
                        </div>
                      </a>
                    </div>
                    <div className="mainFollowUsItem">
                      <a
                        href="https://www.instagram.com/dressbeyondtherunway/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="social_instagram"
                          style={{
                            background: `url(${SOCIAL_IMG}) -54px -10px`,
                          }}
                        >
                          &nbsp;
                        </div>
                      </a>
                    </div>
                    <div className="mainFollowUsItem">
                      <a
                        href="https://www.pinterest.ca/dressbeyondtherunway/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="social_pinterest"
                          style={{
                            background: `url(${SOCIAL_IMG}) -98px -10px`,
                          }}
                        >
                          &nbsp;
                        </div>
                      </a>
                    </div>
                    <div className="mainFollowUsItem">
                      <a
                        href="https://twitter.com/RunwayRentalCA/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="social_twitter"
                          style={{
                            background: `url(${SOCIAL_IMG}) -142px -10px`,
                          }}
                        >
                          &nbsp;
                        </div>
                      </a>
                    </div>
                    <div className="mainFollowUsItem">
                      <a
                        href="https://www.youtube.com/channel/UCMKmhoZ5lU9gJCmJVGH2Y6g/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <div
                          className="social_youtube"
                          style={{
                            background: `url(${SOCIAL_IMG}) -186px -10px`,
                          }}
                        >
                          &nbsp;
                        </div>
                      </a>
                    </div>
                  </div>
                </FollowUs>
                <br />
                <p>
                  <FormattedMessage
                    id="Menu.Visit.Our.Blog"
                    defaultMessage="Visit our "
                  />
                  <a href="/blog">Blog</a>
                </p>
              </Col>
              <img
                src="https://firebasestorage.googleapis.com/v0/b/beyondtherunway-fc203.appspot.com/o/secure-stripe-payment-logo.png?alt=media&token=3fdd320d-4ed4-404d-8be6-cebc167cdeed"
                className="secure-logo"
                alt="footer stripe payment methods"
              />
            </Row>
          </div>
      </IntlProvider>
    );
	}
}

function mapStateToProps(state) {
	return { user: state.Auth.user, view: state.App.view };
}
export const MemoizeFooter = React.memo(FooterApp);
export default connect(
	mapStateToProps,
	{ logout, changeLoginPopover }
)(MemoizeFooter);

const FollowUs = styled.div`
  div.mainFollowUs {
    display: -webkit-flex;
    display: flex;
    div.mainFollowUsItem {
      -webkit-flex: 1;
      -ms-flex: 1;  
      flex: 1;
      .social_facebook, .social_instagram, .social_pinterest, .social_twitter, .social_youtube {
        width: 24px; height: 24px;
      }
    }
  }
  p {
    padding-top: 20px;
  }
  .blog-link {
    :hover {
      text-decoration: underline !important;  
    }
    color: #000000;
  }
`;
