import {all, takeEvery, put} from 'redux-saga/effects';
import actions from './actions';
import initdata from '../../containers/Ecommerce/cart/config';

export function* changedCard() {
  yield takeEvery(actions.CHANGE_CARDS, function* () {
  });
}

export function* initData() {
  let initProduct = initdata;
  yield put({
	type: actions.INIT_DATA,
	payload: initProduct
  });
}

export function* updateData({products, productQuantity}) {
  localStorage.setItem('cartProductQuantity', JSON.stringify(productQuantity));
  localStorage.setItem('cartProducts', JSON.stringify(products));
  yield put({
	type: actions.UPDATE_DATA,
	products,
	productQuantity
  });
}

export function* orderPlaced({payload}) {
  localStorage.removeItem('cartProductQuantity');
  localStorage.removeItem('cartProducts');
  yield put({
	type: actions.UPDATE_DATA,
	products: {},
	productQuantity: []
  });
}

export function* updateQuantity({products, productQuantity}) {
  localStorage.setItem('cartProductQuantity', JSON.stringify(productQuantity));
  localStorage.setItem('cartProducts', JSON.stringify(products));
  yield put({
	type: actions.UPDATE_DATA,
	products,
	productQuantity
  });
}

export default function* () {
  yield all([
	takeEvery(actions.INIT_DATA_SAGA, initData),
	takeEvery(actions.UPDATE_DATA_SAGA, updateData),
	takeEvery(actions.UPDATE_QUANTITY_SAGA, updateQuantity),
	takeEvery(actions.ORDER_PLACED, orderPlaced),
  ]);
}

