import {all, takeEvery, put, call, fork, select} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import actions from './actions';
import notification from '../../components/notification';
import StoreHelper from './helpers/storeHelpers';

export function* getCategories() {
	yield takeEvery(actions.FETCH_STORE_CATEGORIES, function* () {
		const result = yield call(StoreHelper.getCategories);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_CATEGORIES, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getDesigners() {
	yield takeEvery(actions.FETCH_STORE_DESIGNERS, function* () {
		const result = yield call(StoreHelper.getDesigners);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_DESIGNERS, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getOccasions() {
	yield takeEvery(actions.FETCH_STORE_OCCASIONS, function* () {
		const result = yield call(StoreHelper.getOccasions);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_OCCASIONS, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getFormalities() {
	yield takeEvery(actions.FETCH_STORE_FORMALITIES, function* () {
		const result = yield call(StoreHelper.getFormalities);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_FORMALITIES, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getColors() {
	yield takeEvery(actions.FETCH_STORE_COLORS, function* () {
		const result = yield call(StoreHelper.getColors);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_COLORS, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getFabrics() {
	yield takeEvery(actions.FETCH_STORE_FABRICS, function* () {
		const result = yield call(StoreHelper.getFabrics);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_FABRICS, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getSizes() {
	yield takeEvery(actions.FETCH_STORE_SIZES, function* () {
		const result = yield call(StoreHelper.getSizes);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_SIZES, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getProducts() {
	yield takeEvery(actions.FETCH_STORE_PRODUCTS, function* ({ payload }) {
		yield put({ type: actions.STORE_LOADING, payload: true });
		let filters = { ...payload };
		if ('search' in payload) {
			const storeState = yield select();
			filters.designersList = storeState.Store.designers;
			filters.categoriesList = storeState.Store.categories;
			filters.colorsList = storeState.Store.colors;
			filters.occasionsList = storeState.Store.occasions;
			filters.formalitiesList = storeState.Store.formalities;
		}

		const result = yield call(StoreHelper.getProducts, filters);
		if (result && !result.error) {
			yield put({ type: actions.STORE_LOADING, payload: false });
			var f = payload;
			var index = 'index' in payload ? payload.index : 1;
			var merge = false;
			if (index > 1) {
				merge = true;
			}
			if ('next' in f) {
				delete f['next'];
			}
			if ('index' in f) {
				delete f['index'];
			}
			var items = { 'products': result, 'filters': f, 'index': index, merge };
			yield put({ type: actions.RECEIVED_STORE_PRODUCTS, payload: items });
		} else {
			notification('error', result.error || result);
			yield put({ type: actions.STORE_LOADING, payload: false });
		}
	});
}

export function* getProduct() {
	yield takeEvery(actions.FETCH_STORE_PRODUCT, function* ({ payload }) {
		yield put({ type: actions.STORE_LOADING, payload: true });
		const result = yield call(StoreHelper.getProduct, payload);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_PRODUCT, payload: result });
			yield put({ type: actions.STORE_LOADING, payload: false });
		} else {
			notification('error', result.error || result);
			yield put({ type: actions.STORE_LOADING, payload: false });
		}
	});
}

export function* getUserOrder() {
	yield takeEvery(actions.FETCH_USER_ORDER, function* ({ payload }) {
		yield put({ type: actions.USER_LOADING, payload: true });
		const result = yield call(StoreHelper.getOrder, payload);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_ORDER, payload: result });
			yield put({ type: actions.USER_LOADING, payload: false });
		} else {
			notification('error', result.error || result);
			yield put({ type: actions.USER_LOADING, payload: false });
		}
	});
}

export function* getReviews() {
	yield takeEvery(actions.FETCH_USER_REVIEWS, function* ({ payload }) {
		const result = yield call(StoreHelper.getReviews, payload);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_USER_REVIEWS, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getProductReview() {
	yield takeEvery(actions.FETCH_PRODUCT_REVIEWS, function* ({ payload }) {
		const result = yield call(StoreHelper.getProductReview, payload);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_PRODUCT_REVIEWS, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* submitReview() {
	yield takeEvery(actions.ADD_PRODUCT_REVIEW, function* ({ payload }) {
		const result = yield call(StoreHelper.submitReview, payload);
		if (result && !result.error) {
			notification('success', "Review Submitted");
			yield put(push('/account?menu=review'));
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* cancelUserSubscription() {
	yield takeEvery(actions.CANCEL_USER_SUBSCRIPTION, function* ({ payload }) {
		yield put({ type: actions.USER_LOADING, payload: true });
		const result = yield call(StoreHelper.cancelUserSubscription, payload);
		if (result && !result.error) {
			notification('success', "Subscription will be cancelled at end of billing period.");
			yield put({ type: actions.UPDATE_USER_SUBSCRIPTIONS, payload: result });
			yield put({ type: actions.USER_LOADING, payload: false });
			yield put(push('/account?menu=dashboard'));
		} else {
			notification('error', result.error || result);
			yield put({ type: actions.USER_LOADING, payload: false });
		}
	});
}

export function* activateUserSubscription() {
	yield takeEvery(actions.ACTIVATE_USER_SUBSCRIPTION, function* ({ payload }) {
		yield put({ type: actions.USER_LOADING, payload: true });
		const result = yield call(StoreHelper.activateUserSubscription, payload);
		if (result && !result.error) {
			yield put({ type: actions.UPDATE_USER_SUBSCRIPTIONS, payload: result });
			yield put({ type: actions.USER_LOADING, payload: false });
		} else {
			notification('error', result.error || result);
			yield put({ type: actions.USER_LOADING, payload: false });
		}
	});
}

export function* reactivateMembership() {
	yield takeEvery(actions.REACTIVATE_USER_SUBSCRIPTION, function* ({ payload }) {
		yield put({ type: actions.USER_LOADING, payload: true });
		const result = yield call(StoreHelper.reactivateMembership, payload);
		if (result && !result.error) {
			notification('success', "Your subscription has been reactivated.");
			yield put({ type: actions.USER_LOADING, payload: false });
			yield put({ type: actions.UPDATE_USER_SUBSCRIPTIONS, payload: result });
			yield put(push('/account?menu=dashboard'));
		} else {
			notification('error', result.error || result);
			yield put({ type: actions.USER_LOADING, payload: false });
		}
	});
}

export function* getOrders() {
	yield takeEvery(actions.FETCH_STORE_ORDERS, function* ({ payload }) {
		const result = yield call(StoreHelper.getOrders, payload);
		// console.log(result);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_ORDERS, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getOrdersLimit() {
	yield takeEvery(actions.FETCH_STORE_ORDERS_LIMIT, function* ({ payload }) {
		const result = yield call(StoreHelper.getOrdersLimit, payload);
		// console.log(result);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_ORDERS_LIMIT, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getOrder() {
	yield takeEvery(actions.FETCH_STORE_ORDER, function* ({ payload }) {
		const result = yield call(StoreHelper.getOrder, payload);
		// console.log(result);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_ORDER, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export function* getWishlist() {
	yield takeEvery(actions.FETCH_STORE_WISHLIST, function* ({ payload }) {
		const result = yield call(StoreHelper.getWishlist, payload);
		// console.log(result);
		if (result && !result.error) {
			yield put({ type: actions.RECEIVED_STORE_WISHLIST, payload: result });
		} else {
			notification('error', result.error || result);
		}
	});
}

export default function* rootSaga() {
  yield all([
	fork(getCategories),
	fork(getProducts),
	fork(getDesigners),
	fork(getOccasions),
	fork(getFormalities),
	fork(getColors),
	fork(getProduct),
	fork(getFabrics),
	fork(getSizes),
	fork(cancelUserSubscription),
	fork(activateUserSubscription),
	fork(getOrders),
	fork(getOrdersLimit),
	fork(getOrder),
	fork(getWishlist),
	fork(reactivateMembership),
	fork(getUserOrder),
	fork(submitReview),
	fork(getReviews),
	fork(getProductReview)
  ]);
}
