import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Form, Icon, Input, Button, Checkbox} from 'antd';
import Firebase from '../../helpers/firebase';
import authAction from '../../redux/auth/actions';
import SignUpStyleWrapper from "./signup.style";
import {FormattedMessage, IntlProvider} from "react-intl";
import localeData from "../../locales/data.json";
import {LangContext} from "../../langContext";
import {notif} from "../../helpers/utility";
// import { FM } from "../../languageProvider/FMreactIntl";

const db = Firebase.database;
const {login} = authAction;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      admin: false,
      locale: "en"
    }
  }

  componentDidMount() {
    this.props.form.validateFields();
  }

  handleLogin = async (payload) => {
    const {email, password} = payload;
    const self = this;
    let data;
    data = await Firebase.login(Firebase.EMAIL, {
      email,
      password,
    });

    if (data.user) {
      const user = data.user;
      const token = await user.getIdToken();
      const payload = {'token': token, 'user': user};
      this.props.login(payload);
      db.collection("users").doc(user.uid).get().then(function (doc) {
        if (doc.exists) {
          if (doc.data().is_admin) {
            self.setState({admin: doc.data().is_admin});
          }
        }
      }).catch(function (error) {
        console.log("Error getting document:", error);
      });
    } else {
      notif('error', data.message, data.message, this.context[0]);
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const {email, password} = values;
        this.handleLogin({email, password})
      } else {
        if ('email' in err) {
          notif('error', err.email.errors[0].message, err.email.errors[0].message, this.context[0]);
        }
        if ('password' in err) {
          notif('error', err.password.errors[0].message, err.password.errors[0].message, this.context[0]);
        }
      }
    });
  };

  isSignUpPageOpen() {
    let pathname = window.location.pathname;
    if(pathname === '/signin' || pathname === '/signIn') {
      return <a href='/signup'><FormattedMessage id="Menu.NoAccountRegisterNow" defaultMessage="Don't have an account? Sign up here."/></a>
    } else {
      return <a onClick={this.props.signin} href={'#signup-now'}>
        <FormattedMessage id="Menu.NoAccountRegisterNow" defaultMessage="Don't have an account? Sign up here."/>
      </a>;
    }
  }

  resetPassword = () => {
    const {email} = this.state;
    if (!email) {
      notif('error', "Please input a valid email address.", "Veuillez saisir un courriel valide !", this.context[0]);
      return;
    }
    Firebase.resetPassword(email).then(() => {
      notif('success', `Password reset email sent to ${email}.`, `Courriel de réinitialisation du mot de passe envoyé à ${email}`, this.context[0]);
    }).catch(e => {
      console.error(e);
      notif('error', "Email address not found.", "Cette adresse email n’existe pas !", this.context[0]);
    });
  };

  static contextType = LangContext;

  render() {
    const {getFieldDecorator, getFieldError, isFieldTouched} = this.props.form;
    const usernameError = isFieldTouched('email') && getFieldError('email');
    const passwordError = isFieldTouched('password') && getFieldError('password');
    if (this.props.isLoggedIn) {
      if (this.state.admin) {
        window.location.href = window.location.origin.concat('/admin/dashboard');
      } else {
        if (window.location.pathname === "/signin") {
          window.location.href = window.location.origin.concat('/account');
        }
      }
    }
    let lang = this.context[0];

    const getErrorMessages = (lang) => {
      const msg = {
        en: {
          email: 'Invalid email!',
          password: 'Invalid password!',
        },
        fr: {
          email: 'Courriel invalid!',
          password: 'Mot de passe invalide!',
        }
      }[lang]

      return msg;
    };
    // console.log(lang);

    return <IntlProvider
        locale={lang}
        messages={localeData[lang]}
        defaultLocale="en">
      <SignUpStyleWrapper>
        <div className="isoSignUpContentWrapper">
          <div className="isoSignUpContent" style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '20px'}}>
            <div className="isoSignUpForm">
              <div className="login-form-wrapper">
                <Form className="login-form" onSubmit={this.handleSubmit}>
                  <div style={{marginBottom: '15px'}}>
                    <Form.Item key="username" validateStatus={usernameError ? 'error' : ''} help={usernameError || ''}>
                      {getFieldDecorator('email', {
                        rules: [{ type: 'email', required: true, message: getErrorMessages(lang).email, whitespace: true,}],
                        setFieldValue: this.state.email
                      }, )(
                        <Input
                            allowClear
                          prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                          placeholder="Email address / Courriel"
                          onChange={e => {
                            this.setState({email: e.target.value})
                          }}
                        />,
                      )}
                    </Form.Item>
                  </div>
                  <Form.Item key="password" validateStatus={passwordError ? 'error' : ''} help={passwordError || ''}>
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: getErrorMessages(lang).password}],
                    })(
                      <Input.Password
                        prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                        type="password"
                        placeholder="Password / Mot de passe"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item key="remember">
                    {getFieldDecorator('remember', {
                      valuePropName: 'checked',
                      initialValue: true,
                    })(<Checkbox><FormattedMessage id="Menu.Remember.me" defaultMessage="Remember me"/></Checkbox>)}
                    <a className="login-form-forgot" onClick={this.resetPassword} href={'#forgot-password'} style={{float: 'right'}}>
                      <FormattedMessage id="Menu.Forgot.password" defaultMessage="Forgot password"/>
                    </a>
                    <Button type="primary" htmlType="submit" className="ant-btn btr-black-btn"
                    style={{marginTop: '10px'}}>
                      <FormattedMessage id="Menu.Sign.in" defaultMessage="Sign In"/>
                    </Button>
                    <p className="new-account-wrapper">
                      {this.isSignUpPageOpen()}
                    </p>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </SignUpStyleWrapper>
    </IntlProvider>;
  }
}

const WrappedSignInForm = Form.create({name: 'signin_login'})(SignInFormBase);
export default connect(
  state => ({
    isLoggedIn: state.Auth.user !== null,
  }),
  {login}
)(WrappedSignInForm);

