export default {
  apiUrl: 'http://yoursite.com/api/'
};
const siteConfig = {
  siteName: 'Beyond The Runway',
  siteIcon: 'ion-flash',
  footerText: '@2020 Beyond the Runway Inc.'
};

const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';
const AlgoliaSearchConfig = {
  appId: '',
  apiKey: ''
};
const Auth0Config = {
  domain: '',
  clientID: '',
  allowedConnections: ['Username-Password-Authentication'],
  rememberLastLogin: true,
  language: 'en',
  closable: true,
  options: {
	auth: {
	  autoParseHash: true,
	  redirect: true,
	  redirectUrl: 'http://localhost:3000/auth0loginCallback'
	},
	languageDictionary: {
	  title: 'Beyond The Runway',
	  emailInputPlaceholder: 'demo@gmail.com',
	  passwordInputPlaceholder: 'demodemo'
	},
	theme: {
	  labeledSubmitButton: true,
	  logo: '',
	  primaryColor: '#E14615',
	  authButtons: {
		connectionName: {
		  displayName: 'Log In',
		  primaryColor: '#b7b7b7',
		  foregroundColor: '#000000'
		}
	  }
	}
  }
};
const firebaseConfig = {
  apiKey: 'AIzaSyAQj5nt8erybGdY03ilAK2w2YxyidV3IuQ',
  authDomain: 'https://beyondtherunway-fc203.web.app',
  projectId: 'beyondtherunway-fc203',
  storageBucket: 'gs://beyondtherunway-fc203.appspot.com/',
  messagingSenderId: ''
};
const googleConfig = {
  apiKey: '' //
};
const mapboxConfig = {
  tileLayer: '',
  maxZoom: '',
  defaultZoom: '',
  center: []
};
const youtubeSearchApi = '';
export {
  siteConfig,
  themeConfig,
  language,
  AlgoliaSearchConfig,
  Auth0Config,
  firebaseConfig,
  googleConfig,
  mapboxConfig,
  youtubeSearchApi
};

export const REFERRED_EMAIL_GET = 20;
export const REFERRER_GET = 30;
export const GOLD_ID = "8CEKaQzAHkJCvu47WSaL";
export const SILVER_ID = "cZAKiJKRkEygng8SHX9l";
export const LOAD_TEXT = "Loading...";