import {all, takeEvery, put, fork, call} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import {getToken, clearToken, getUser, clearUser} from '../../helpers/utility';
import actions from './actions';
import appActions from '../app/actions';
import notification from '../../components/notification';
import {saveState} from '../../helpers/localStorage';
import AuthHelper from './helpers/authHelper';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function* ({payload}) {
	const {token} = payload;
	const result = yield call(AuthHelper.getUser, token.user.uid);
	if (result && !result.error) {
	  const user = result;
	  user.uid = token.user.uid;
	  user.email = token.user.email;
	  yield put({
		type: actions.LOGIN_SUCCESS,
		token: token.token,
		user: user,
	  });
	  yield put({type: appActions.CHANGE_LOGIN_POPOVER});
	} else {
	  yield put({type: actions.LOGIN_ERROR, payload: result});
	}
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (payload) {
	yield localStorage.setItem('id_token', payload.token);
	yield saveState('user', payload.user);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function* (payload) {
	notification('error', payload.payload.error);
	yield put(push('/'));
  });
}

export function* logout() {
  yield takeEvery(actions.LOGOUT, function* () {
	clearToken();
	yield put(push('/'));
  });
}

export function* signupUser() {
  yield takeEvery(actions.SIGNUP_USER, function* ({payload}) {
	const result = yield call(AuthHelper.signupUser, payload);
	if (result && !result.error) {
	  yield put({
		type: actions.LOGIN_SUCCESS,
		token: result.token,
		user: result.user,
	  });
	  yield put({
		type: actions.LOGIN_SUCCESS,
		token: result.token,
		user: result.user,
	  });
	  yield put({type: appActions.CHANGE_LOGIN_POPOVER});
	} else {
	  notification('error', result.error.message || result);
	}
  });
}

export function* checkAuthorization() {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function* () {
	const token = getToken().get('idToken');
	const user = getUser().get('user');
	if (token && user) {
	  // var loggedin = firebase.auth().currentUser;
	  yield put({
		type: actions.LOGIN_SUCCESS,
		token,
		user,
	  });
	}
  });
}

export function* fetchCurrentUserInfo() {
  yield takeEvery(actions.FETCH_USER_CURRENT_INFO, function* ({payload}) {
	const result = yield call(AuthHelper.getUser, payload);
	if (result && !result.error) {
	  yield put({
		type: actions.UPDATE_USER,
		payload: result
	  });
	  yield saveState('user', result);
	} else {
	  clearToken();
	  clearUser();
	  yield put(push('/signin'));
	}
  });
}

export function* updateWishlist() {
  yield takeEvery(actions.UPDATE_USER_WISHLIST, function* ({payload}) {
	const result = yield call(AuthHelper.updateWishlist, payload);
	if (result && !result.error) {
	  const user = getUser().get('user');
	  user.wishlist = result;
	  yield saveState('user', user);
	  yield put({type: actions.RECEIVED_USER_ADDRESS, payload: {'wishlist': result}});
	} else {
	  notification('error', result.error || result);
	}
  });
}

export default function* rootSaga() {
  yield all([
	fork(checkAuthorization),
	fork(loginRequest),
	fork(loginSuccess),
	fork(loginError),
	fork(logout),
	fork(signupUser),
	fork(fetchCurrentUserInfo),
	fork(updateWishlist)
  ]);
}
