import {all, takeEvery, put, call, fork} from 'redux-saga/effects';
import {push} from 'react-router-redux';
import actions from './actions';
import notification from '../../../components/notification';
import ProductsHelper from './helpers/productsHelper';
///////////////////////////////////////////////////////////////////////////////////////
/// ADD|CREATE
///////////////////////////////////////////////////////////////////////////////////////
export function* addColor() {
  yield takeEvery(actions.ADD_ADMIN_COLOR, function* ({payload}) {
	const result = yield call(ProductsHelper.addColor, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_COLOR, payload: result});
	  notification('success', "Color Added");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addFabric() {
  yield takeEvery(actions.ADD_ADMIN_FABRIC, function* ({payload}) {
	const result = yield call(ProductsHelper.addFabric, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_FABRIC, payload: result});
	  notification('success', "Fabric Added");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addOccasion() {
  yield takeEvery(actions.ADD_ADMIN_OCCASION, function* ({payload}) {
	const result = yield call(ProductsHelper.addOccasion, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_OCCASION, payload: result});
	  notification('success', "Occasion Added");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addFormality() {
  yield takeEvery(actions.ADD_ADMIN_FORMALITY, function* ({payload}) {
	const result = yield call(ProductsHelper.addFormality, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_FORMALITY, payload: result});
	  notification('success', "Formality Added");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addSize() {
  yield takeEvery(actions.ADD_ADMIN_SIZE, function* ({payload}) {
	const result = yield call(ProductsHelper.addSize, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_SIZE, payload: result});
	  notification('success', "Size Added");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addDesigner() {
  yield takeEvery(actions.ADD_ADMIN_DESIGNER, function* ({payload}) {
	const result = yield call(ProductsHelper.addDesigner, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_DESIGNER, payload: result});
	  notification('success', "Designer Added");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addCategory() {
  yield takeEvery(actions.ADD_ADMIN_CATEGORY, function* ({payload}) {
	const result = yield call(ProductsHelper.addCategory, payload);
	console.log(result);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_CATEGORY, payload: result});
	  notification('success', "Category Added");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addNewProduct() {
  yield takeEvery(actions.ADD_ADMIN_PRODUCT, function* ({payload}) {
	const result = yield call(ProductsHelper.addNewProduct, payload);
	if (result && !result.error) {
	  notification('success', "Product Added");
	  yield put(push(`/admin/products/${result.id}`));
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* addBulkProducts() {
  yield takeEvery(actions.ADD_BULK_PRODUCTS, function* ({payload}) {
	const result = yield call(ProductsHelper.addBulkProducts, payload);
	if (result && !result.error) {
	  notification('success', "Products Added");
	  yield put({type: actions.RECEIVED_MISSING_BULK, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
///////////////////////////////////////////////////////////////////////////////////////
/// DELETE
///////////////////////////////////////////////////////////////////////////////////////
export function* deleteOccasion() {
  yield takeEvery(actions.DELETE_ADMIN_OCCASION, function* ({payload}) {
	const result = yield call(ProductsHelper.deleteOccasion, payload);
	if (result && !result.error) {
	  notification('success', "Occasion successfully deleted");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* deleteColor() {
  yield takeEvery(actions.DELETE_ADMIN_COLOR, function* ({payload}) {
	const result = yield call(ProductsHelper.deleteColor, payload);
	if (result && !result.error) {
	  notification('success', "Color successfully deleted");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* deleteSize() {
  yield takeEvery(actions.DELETE_ADMIN_SIZE, function* ({payload}) {
	const result = yield call(ProductsHelper.deleteSize, payload);
	if (result && !result.error) {
	  notification('success', "Size successfully deleted");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* deleteFormality() {
  yield takeEvery(actions.DELETE_ADMIN_FORMALITY, function* ({payload}) {
	const result = yield call(ProductsHelper.deleteFormality, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Formality successfully deleted");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* deleteDesigner() {
  yield takeEvery(actions.DELETE_ADMIN_DESIGNER, function* ({payload}) {
	const result = yield call(ProductsHelper.deleteDesigner, payload);
	if (result && !result.error) {
	  notification('success', "Designer successfully deleted");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* deleteCategories() {
  yield takeEvery(actions.DELETE_ADMIN_CATEGORY, function* ({payload}) {
	const result = yield call(ProductsHelper.deleteCategories, payload);
	if (result && !result.error) {
	  notification('success', "Category successfully deleted");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* deleteProduct() {
  yield takeEvery(actions.DELETE_ADMIN_PRODUCT, function* ({payload}) {
	const result = yield call(ProductsHelper.deleteProduct, payload);
	if (result && !result.error) {
	  notification('success', "Product successfully deleted");
	  yield put(push(`/admin/products`));
	} else {
	  notification('error', result.error || result);
	}
  });
}
///////////////////////////////////////////////////////////////////////////////////////
/// GET|FETCH
///////////////////////////////////////////////////////////////////////////////////////
export function* getAppSettings() {
  yield takeEvery(actions.APP_SETTINGS, function* ({payload}) {
	const result = yield call(ProductsHelper.getAppSettings, payload);
	// console.log(result);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_APP_SETTINGS, payload: result});
	}
  });
}
export function* getProducts() {
  yield takeEvery(actions.FETCH_ADMIN_PRODUCTS, function* ({payload}) {
	const result = yield call(ProductsHelper.getProducts);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_PRODUCTS, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* getDesigners() {
  yield takeEvery(actions.FETCH_ADMIN_DESIGNERS, function* ({payload}) {
	const result = yield call(ProductsHelper.getDesigners);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_DESIGNERS, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* getUsers() {
  yield takeEvery(actions.FETCH_ADMIN_USERS, function* ({payload}) {
	const result = yield call(ProductsHelper.getUsers);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_USERS, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* getUser() {
  yield takeEvery(actions.FETCH_ADMIN_USER, function* ({payload}) {
	const result = yield call(ProductsHelper.getUser, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_USER, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* getOrders() {
  yield takeEvery(actions.FETCH_ADMIN_ORDERS, function* ({payload}) {
	const result = yield call(ProductsHelper.getOrders, payload);
	// console.log(result);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_ORDERS, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* getReviews() {
  yield takeEvery(actions.FETCH_ADMIN_REVIEWS, function* ({payload}) {
	const result = yield call(ProductsHelper.getReviews, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_REVIEWS, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* getReview() {
  yield takeEvery(actions.FETCH_ADMIN_REVIEW, function* ({payload}) {
	const result = yield call(ProductsHelper.getReview, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_REVIEW, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
///////////////////////////////////////////////////////////////////////////////////////
/// UPDATE
///////////////////////////////////////////////////////////////////////////////////////
export function* updateAppSettings() {
  yield takeEvery(actions.UPDATE_ADMIN_APP_SETTINGS, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAppSettings, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_APP_SETTINGS, payload: result});
	  notification('success', "Settings Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateProduct() {
  yield takeEvery(actions.UPDATE_ADMIN_PRODUCT, function* ({payload}) {
	const result = yield call(ProductsHelper.updateProduct, payload);
	if (result && !result.error) {
	  yield put({type: actions.RECEIVED_ADMIN_PRODUCT, payload: result});
	  notification('success', "Product Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateAdminUser() {
  yield takeEvery(actions.UPDATE_ADMIN_USER, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAdminUser, payload);
	if (result && !result.error) {
	  console.log(result);
	  yield put({type: actions.RECEIVED_ADMIN_USER, payload: result});
	  notification('success', "User Updates");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateOrder() {
  yield takeEvery(actions.UPDATE_ADMIN_ORDER, function* ({payload}) {
	const result = yield call(ProductsHelper.updateOrder, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Order Updated");
	  yield put({type: actions.RECEIVED_ADMIN_ORDER, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateProductImage() {
  yield takeEvery(actions.UPDATE_PRODUCT_IMAGE, function* ({payload}) {
	const result = yield call(ProductsHelper.updateProductImage, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Products Image Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateAdminCategories() {
  yield takeEvery(actions.UPDATE_ADMIN_CATEGORIES, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAdminCategory, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Category Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateAdminDesigners() {
  yield takeEvery(actions.UPDATE_ADMIN_DESIGNER, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAdminDesigner, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Category Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateAdminColor() {
  yield takeEvery(actions.UPDATE_ADMIN_COLOR, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAdminColor, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Color Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateAdminSize() {
  yield takeEvery(actions.UPDATE_ADMIN_SIZE, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAdminSize, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Size Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateAdminOccasion() {
  yield takeEvery(actions.UPDATE_ADMIN_OCCASION, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAdminOccasion, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Occasion Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* updateAdminFormalities() {
  yield takeEvery(actions.UPDATE_ADMIN_FORMALITY, function* ({payload}) {
	const result = yield call(ProductsHelper.updateAdminFormalities, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Formality Updated");
	} else {
	  notification('error', result.error || result);
	}
  });
}

export function* saveAdminReview() {
  yield takeEvery(actions.UPDATE_ADMIN_REVIEW, function* ({payload}) {
	const result = yield call(ProductsHelper.saveAdminReview, payload);
	if (result && !result.error) {
	  notification('success', "Review Saved");
	  yield put({type: actions.RECEIVED_ADMIN_REVIEW, payload: result});
	} else {
	  notification('error', result.error || result);
	}
  });
}
export function* sendShippingEmail() {
  yield takeEvery(actions.SEND_ADMIN_SHIPPING_CONFIRMATION_EMAIL, function* ({payload}) {
	const result = yield call(ProductsHelper.sendShippingEmail, payload);
	console.log(result);
	if (result && !result.error) {
	  notification('success', "Shipping Email Sent");
	} else {
	  notification('error', result.error || result);
	}
  });
}

export default function* rootSaga() {
  yield all([
	fork(addColor),
	fork(addFabric),
	fork(addOccasion),
	fork(addFormality),
	fork(addSize),
	fork(addDesigner),
	fork(addNewProduct),
	fork(addBulkProducts),
	fork(addCategory),
	fork(getProducts),
	fork(getDesigners),
	fork(getUsers),
	fork(getUser),
	fork(getOrders),
	fork(getAppSettings),
	fork(getReviews),
	fork(getReview),
	fork(deleteDesigner),
	fork(deleteProduct),
	fork(deleteCategories),
	fork(deleteFormality),
	fork(deleteSize),
	fork(deleteColor),
	fork(deleteOccasion),
	fork(updateOrder),
	fork(updateProduct),
	fork(updateProductImage),
	fork(updateAdminCategories),
	fork(updateAdminColor),
	fork(updateAdminSize),
	fork(updateAdminOccasion),
	fork(updateAdminFormalities),
	fork(updateAdminUser),
	fork(updateAdminDesigners),
	fork(updateAppSettings),
	fork(saveAdminReview),
	fork(sendShippingEmail)
  ]);
}



   
