import FirebaseHelper from '../../../helpers/firebase';
import firebase from 'firebase';

const db = FirebaseHelper.database;

class AuthHelper {

  getUser = async payload => {
	const dataRef = db.collection('users').doc(payload);
	return await dataRef.get().then(function (doc) {
	  if (doc.exists) {
		let user = doc.data();
		user.uid = doc.id;
		return user;
	  } else {
		// doc.data() will be undefined in this case
		return {'error': "No User found"};
	  }
	}).catch(function (error) {
	  return {'error': "No User found"};
	});
  };

  loginUser = async payload => {
	return await firebase.auth().signInWithCustomToken({'token': payload.token}).catch(function (error) {
	  console.info(error);
	  return {'error': error.message};
	});
  };

  signupUser = async payload => {
	return await firebase.auth().createUserWithEmailAndPassword(
		payload.email,
		payload.password
	).then(async function () {
      const user_id = firebase.auth().currentUser.uid;
      let u = {};
      u.first_name = payload.first_name;
	  u.last_name = payload.last_name;
	  u.email = payload.email;
	  u.sign_up_date = payload.sign_up_date;
	  await db.collection('users').doc(user_id).set(u);
	  u.uid = user_id;
	  u.displayName = payload.first_name;
	  const token = await firebase.auth().currentUser.getIdToken();
	  return {'token': token, 'user': u};
	}).catch(function (error) {
	  return {'error': error};
	});
  };

  updateWishlist = async payload => {
    const user_id = firebase.auth().currentUser.uid;
    const dataRef = db.collection('users').doc(user_id);
	return await dataRef.set({wishlist: payload}, {merge: true})
		.then(function () {
		  return payload;
		}).catch(function (error) {
		  return error;
		});
  };

}

export default new AuthHelper();
