import React, {Component} from 'react';
import {Menu} from 'antd';
import {makeId} from "../../helpers/utility";
import {FormattedMessage} from "react-intl";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class MenuApp extends Component {
  constructor(props) {
	super(props);
	this.state = {
	  current: '1',
	  openKeys: [],
	  theme: 'dark',
	  mode: 'inline'
	};
  }

  handleClick = e => {
	this.setState({
	  current: e.key
	});
  };

  changeMode = value => {
	this.setState({
	  mode: value ? 'vertical' : 'inline'
	});
  };

  onOpenChange = openKeys => {
	const state = this.state;
	const latestOpenKey = openKeys.find(
		key => !(state.openKeys.indexOf(key) > -1)
	);
	const latestCloseKey = state.openKeys.find(
		key => !(openKeys.indexOf(key) > -1)
	);

	let nextOpenKeys = [];
	if (latestOpenKey) {
	  nextOpenKeys = this.getAncestorKeys(latestOpenKey).concat(latestOpenKey);
	}
	if (latestCloseKey) {
	  nextOpenKeys = this.getAncestorKeys(latestCloseKey);
	}
	this.setState({openKeys: nextOpenKeys});
  };

  getAncestorKeys = key => {
	const map = {
	  sub3: ['sub2']
	};
	return map[key] || [];
  };

  changeTheme = value => {
	this.setState({
	  theme: value ? 'dark' : 'light'
	});
  };

  render() {
	return <>
	  <div className="isoContent">
		<Menu
			onClick={this.handleClick}
			selectedKeys={[this.state.current]}
			mode={this.props.view === "DesktopView" ? "horizontal" : "vertical"}>
		  <SubMenu title={<a href="/shop" style={{color: '#222'}}>
			<FormattedMessage id="Menu.Clothing" defaultMessage="Clothing"/>
		  </a>}>
			<Menu.Item key={makeId(5)}><a href={`/shop/categories/blazers`}><FormattedMessage id="Menu.Clothing.Blazers"
																							  defaultMessage="Blazers"/></a></Menu.Item>
			<Menu.Item key={makeId(5)}><a href={`/shop/categories/cardigans`}><FormattedMessage
				id="Menu.Clothing.Cardigans" defaultMessage="Cardigans"/></a></Menu.Item>
			<Menu.Item key={makeId(5)}><a href={`/shop/categories/coats`}><FormattedMessage id="Menu.Clothing.Coats"
																							defaultMessage="Coats"/></a></Menu.Item>
			<Menu.Item key={makeId(5)}><a href={`/shop/categories/dresses`}><FormattedMessage id="Menu.Clothing.Dresses"
																							  defaultMessage="Dresses"/></a></Menu.Item>
			<Menu.Item key={makeId(5)}><a href={`/shop/categories/jackets`}><FormattedMessage id="Menu.Clothing.Jackets"
																							  defaultMessage="Jackets"/></a></Menu.Item>
			<Menu.Item key={makeId(5)}><a href={`/shop/categories/jumpsuits-rompers`}><FormattedMessage
				id="Menu.Clothing.JumpsuitsRompers" defaultMessage="Jumpsuit & Rompers"/></a></Menu.Item>
			<Menu.Item><a onClick={() => this.props.updateFilter({'junior': 'Junior'})} href={'#junior'}>Junior</a>
			</Menu.Item>
		  </SubMenu>
		  <SubMenu title={<a href="/shop/dresses" style={{color: '#222'}}>
			<FormattedMessage id="Menu.Dresses" defaultMessage="Dresses"/></a>}>
			<MenuItemGroup title={<FormattedMessage id="Menu.Dresses.Length" defaultMessage="Length"/>}>
			  <Menu.Item key={makeId(5)}><a href="/shop/dresses/long-dresses">
				<FormattedMessage id="Menu.Dresses.Length.Long" defaultMessage="Long Dresses"/></a>
			  </Menu.Item>
			  <Menu.Item key={makeId(5)}><a href="/shop/dresses/midi-length-dresses">
				<FormattedMessage id="Menu.Dresses.Length.Midi" defaultMessage="Midi-length Dresses"/></a>
			  </Menu.Item>
			  <Menu.Item key={makeId(5)}><a href="/shop/dresses/short-dresses">
				<FormattedMessage id="Menu.Dresses.Length.Short" defaultMessage="Short Dresses"/></a>
			  </Menu.Item>
			</MenuItemGroup>
			<MenuItemGroup title={<FormattedMessage id="Menu.Occasions" defaultMessage="Occasions"/>}>
			  <Menu.Item key={makeId(5)}>
				<a href="/shop/occasions/bridal"><FormattedMessage id="Menu.Occasions.Bridal" defaultMessage="Bridal"/></a>
			  </Menu.Item>
			  <Menu.Item key={makeId(5)}>
				<a href="/shop/occasions/party"><FormattedMessage id="Menu.Occasions.Party"
																  defaultMessage="Cocktail & Party"/></a>
			  </Menu.Item>
			  <Menu.Item key={makeId(5)}>
				<a href="/shop/occasions/gala"><FormattedMessage id="Menu.Occasions.Gala" defaultMessage="Gala"/></a>
			  </Menu.Item>
			  <Menu.Item key={makeId(5)}>
				<a href="/shop/occasions/work"><FormattedMessage id="Menu.Occasions.Work"
																 defaultMessage="Professional & Work Attire"/></a>
			  </Menu.Item>
			  <Menu.Item key={makeId(5)}>
				<a href="/shop/occasions/prom"><FormattedMessage id="Menu.Occasions.Prom" defaultMessage="Prom"/></a>
			  </Menu.Item>
			  <Menu.Item key={makeId(5)}>
				<a href="/shop/occasions/weekend"><FormattedMessage id="Menu.Occasions.WeekendVacation"
																	defaultMessage="Weekend & Vacation"/></a>
			  </Menu.Item>
			</MenuItemGroup>
		  </SubMenu>
		  <SubMenu title={<a href='/designers' style={{color: '#222'}}>Designers</a>}>
			<Menu.Item key={makeId(5)}>
			  <a href="/designers"><FormattedMessage id="Heading.All.Designers" defaultMessage="All designers"/></a>
			</Menu.Item>
			<MenuItemGroup title={<FormattedMessage id="Heading.Featured.Designers" defaultMessage="Featured designers" />}>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/anna-sui`}>Anna Sui</a></Menu.Item>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/balenciaga`}>Balenciaga</a></Menu.Item>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/chlo%C3%A9`}>Chloé</a></Menu.Item>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/cinq-a-sept`}>Cinq a Sept</a></Menu.Item>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/dress-the-population`}>Dress The Population</a></Menu.Item>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/nicole-miller`}>Nicole Miller</a></Menu.Item>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/ronny-kobo`}>Ronny Kobo</a></Menu.Item>
			  <Menu.Item key={makeId(5)}><a href={`/shop/designers/tadashi-shoji`}>Tadashi Shoji</a></Menu.Item>
			</MenuItemGroup>
		  </SubMenu>
		  <SubMenu title={<a href='/shop/accessories' style={{color: '#222'}}>
			<FormattedMessage id="Menu.Accessories" defaultMessage="Accessories"/>
		  </a>}>
			<Menu.Item key={makeId(5)}>
			  <a href={`/shop/accessories/handbags`}>
				<FormattedMessage id="Menu.Accessories.Handbags" defaultMessage="HandBags"/>
			  </a>
			</Menu.Item>
			<Menu.Item key={makeId(5)}>
			  <a href={`/shop/accessories/jewellery`}>
				<FormattedMessage id="Menu.Accessories.Jewelery" defaultMessage="Jewelery"/>
			  </a>
			</Menu.Item>
		  </SubMenu>
		  <SubMenu title={
			<FormattedMessage id="HowItWorks.Menu" defaultMessage="How it works"/>}>
			<Menu.Item key={makeId(5)}>
			  <a href={`/how-it-works`}>
				<FormattedMessage id="Text.HowToRent" defaultMessage="How to rent"/>
			  </a>
			</Menu.Item>
			<Menu.Item key={makeId(5)}>
			  <a href={`/faq`}>
				<FormattedMessage id="Menu.Faq" defaultMessage="FAQs"/>
			  </a>
			</Menu.Item>
		  </SubMenu>
			<Menu.Item key={makeId(5)}>
			  <a href="/how-to-sell">
					<FormattedMessage id="BuySell" defaultMessage="Buy & Sell Luxury"/>
			  </a>
			</Menu.Item>
		</Menu>
	  </div>
	</>;
  }
}

export default MenuApp;