import React from "react";
import {Drawer, Divider, Col, Row} from 'antd';
import {connect} from 'react-redux';
import WrappedSignInForm from './signin';
import Logo from '../../image/logo-btr.png';
import SigninWrapper from './signin.style';
import SignUp from './signup';
import action from '../../redux/app/actions';

const {changeLoginPopover} = action;

class UserSidebar extends React.PureComponent {
  constructor(props) {
	super(props);
	this.state = {
	  visible: false,
	  signIn: true
	};
  }

  onClose = () => {
	this.props.changeLoginPopover();
  };

  signin() {
	this.setState({signIn: !this.state.signIn});
  }

  render() {
	return <Drawer
		width={640}
		placement="right"
		className={window.location.pathname === "/account" ? "sidelogin-closed" : "sidelogin"}
		onClose={this.onClose}
		visible={this.props.openLoginDrawer}>
	  <SigninWrapper className="account-menu-wrapper">
		<Row>
		  <Col span={24} className="sidemenu-header">
			<img src={Logo} alt="logo"/>
		  </Col>
		</Row>
		<Divider/>
		<Row className="sidemenu-login-row-wrapper">
		  <Col span={24} className="sidemenu-login-wrapper">
			{this.state.signIn ?
				<WrappedSignInForm signin={this.signin.bind(this)}/>
				:
				<SignUp signin={this.signin.bind(this)}/>
			}
		  </Col>
		</Row>
	  </SigninWrapper>
	</Drawer>;
  }
}


export default connect(
	state => ({
	  openLoginDrawer: state.App.openLoginDrawer,
	}),
	{changeLoginPopover}
)(UserSidebar);