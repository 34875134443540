const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  RECEIVED_USER_ADDRESS: 'RECEIVED_USER_ADDRESS',
  SIGNUP_USER: 'SIGNUP_USER',
  FETCH_USER_CURRENT_INFO: 'FETCH_USER_CURRENT_INFO',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_WISHLIST: 'UPDATE_USER_WISHLIST',

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION
  }),
  login: (token = false) => ({
	type: actions.LOGIN_REQUEST,
	payload: {token},
  }),
  logout: () => ({
	type: actions.LOGOUT,
  }),
  fetchCurrentUserInfo: payload => ({
	type: actions.FETCH_USER_CURRENT_INFO,
	payload
  }),
  signupUser: payload => ({
	type: actions.SIGNUP_USER,
	payload
  }),
  updateWishlist: payload => ({
	type: actions.UPDATE_USER_WISHLIST,
	payload
  })
};
export default actions;
