import React from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { addLocaleData } from 'react-intl';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import { ThemeProvider } from 'styled-components';
import authAction from '../../redux/auth/actions';
import appActions from '../../redux/app/actions';
import shopActions from '../../redux/admin/shop/actions';
import Topbar from '../Topbar/TopbarApp';
import AppRouter from './AppRouter';
import themes from '../../settings/themes';
import AppHolder from './commonStyle';
import FooterApp from '../Footer';
import './global.css';
import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";
import localeData from '../../locales/data.json';
import { LangContext } from "../../langContext";

addLocaleData([...en, ...fr]);

const { Content } = Layout;
const { logout } = authAction;
const { toggleAll } = appActions;
const { getAppSettings } = shopActions;

export class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeClass: this.props.view === 'MobileView' ? 'top hidden' : 'top',
			isScroll: false,
			locale: "en",
			messages: localeData["en"],
			isFrench: false,
		};
		this.languageToggler = this.languageToggler.bind(this);
	}

	componentDidMount() {
		this.props.getAppSettings();
		if (localStorage.getItem("currentLang") !== null) {
			let isFr = { isFrench: false };
			if (localStorage.getItem("currentLang") === "fr") {
				isFr.isFrench = true;
			}
			this.setState({ locale: localStorage.getItem("currentLang"), isFrench: isFr.isFrench });
		}
	}

	shouldComponentUpdate(nextProps) {
		return this.state.locale !== nextProps.locale || this.props.settings !== nextProps.settings;
	}

	languageToggler = async (e) => {
		if (e) {
			//console.log('isFrench => TRUE');
			this.setState({ locale: "fr", isFrench: true });
			localStorage.setItem("currentLang", "fr");
		}
		if (!e) {
			//console.log('isFrench => FALSE');
			this.setState({ locale: "en", isFrench: false });
			localStorage.setItem("currentLang", "en");
		}
	};

	render() {
		const { selectedTheme, height, location } = this.props;

		const storeNotice = () => {
			if ('store_notification' in this.props.settings) {
				let storeNotice = this.state.locale === "en" ? this.props.settings.store_notification : this.props.settings.store_notification_fr; 
				return storeNotice && <div className={"store-notice " + this.state.activeClass}>{storeNotice}</div>;
			}
		};

		return (
			<LangContext.Provider value={[this.state.locale]}>
				<ThemeProvider theme={themes[selectedTheme]}>
					<AppHolder className={this.state.isScroll ? 'app-wrapper sticky' : 'app-wrapper'}>
						<Layout style={{ height: window.innerHeight }}>
							<Debounce time="1000" handler="onResize">
								<WindowResizeListener
									onResize={windowSize =>
										this.props.toggleAll(
											windowSize.windowWidth,
											windowSize.windowHeight
										)
									}
								/>
							</Debounce>
							{location.pathname !== "/buy-sell-checkout" &&
								<> 
									{storeNotice()}
									<Topbar history={this.props.history} languageToggler={this.languageToggler}
										isFrench={this.state.isFrench}
										locale={this.state.locale} />
								</>
							}
							<Layout
								className="isoContentMainLayout"
								style={{
									height: height,
									width: '100%'
								}}
							>
								<Content className={`isomorphicContent css-client ${this.props.view === "MobileView" ? 'mobileview' : 'desktopview'}`}>
									<AppRouter />
								</Content>
								{location.pathname !== "/buy-sell-checkout" && <FooterApp />}
							</Layout>
						</Layout>
					</AppHolder>
				</ThemeProvider>
			</LangContext.Provider>
		);
	}
}

export default connect(
	state => ({
		...state.App,
		auth: state.Auth,
		selectedTheme: state.ThemeSwitcher.changeThemes.themeName,
		height: state.App.height,
		view: state.App.view,
		settings: state.Admin.Shop.settings
	}),
	{ logout, toggleAll, getAppSettings }
)(App);
