import actions from './actions';

const initState = {
  categories: [],
  products: [],
  designers: [],
  occasions: [],
  formalities: [],
  colors: [],
  filters: {},
  product: {},
  fabrics: [],
  sizes: [],
  plan: {},
  invoices: [],
  subscriptions: [],
  loading: false,
  orders: [],
  order: {},
  wishlist: [],
  index: 1,
  reviews: [],
  product_reviews: []
};

export default function storeReducer(state = initState, action) {
  switch (action.type) {
  case actions.RECEIVED_STORE_CATEGORIES:
	return {...state, categories: action.payload};
  case actions.RECEIVED_STORE_PRODUCTS:
	var products = action.payload.merge ? [...state.products, ...action.payload.products] : action.payload.products;
	return {...state, products, filters: action.payload.filters, index: action.payload.index};
  case actions.RECEIVED_STORE_DESIGNERS:
	return {...state, designers: action.payload};
  case actions.RECEIVED_STORE_OCCASIONS:
	return {...state, occasions: action.payload};
  case actions.RECEIVED_STORE_FORMALITIES:
	return {...state, formalities: action.payload};
  case actions.RECEIVED_STORE_COLORS:
	return {...state, colors: action.payload};
  case actions.RECEIVED_STORE_FABRICS:
	return {...state, fabrics: action.payload};
  case actions.RECEIVED_STORE_SIZES:
	return {...state, sizes: action.payload};
  case actions.RECEIVED_STORE_FILTERS:
	return {...state, filters: action.payload};
  case actions.RECEIVED_STORE_PRODUCT:
	return {...state, product: action.payload};
  case actions.RECEIVED_STORE_PLAN:
	return {...state, plan: action.payload};
  case actions.RECEIVED_USER_INVOICES:
	return {...state, invoices: action.payload};
  case actions.USER_LOADING:
	return {...state, loading: action.payload};
  case actions.RECEIVED_STORE_ORDERS:
	return {...state, orders: action.payload};
  case actions.RECEIVED_STORE_ORDERS_LIMIT:
	return {...state, orders: action.payload};
  case actions.RECEIVED_STORE_ORDER:
	return {...state, order: action.payload};
  case actions.RECEIVED_USER_REVIEWS:
	return {...state, reviews: action.payload};
  case actions.RECEIVED_PRODUCT_REVIEWS:
	return {...state, product_reviews: action.payload};
  case actions.STORE_LOADING:
	return {...state, loading: action.payload};
  case actions.RECEIVED_USER_SUBSCRIPTION:
	return {...state, subscriptions: action.payload};
  case actions.UPDATE_USER_SUBSCRIPTIONS:
	const index = state.subscriptions.findIndex((sub) => sub.id === action.payload.id);
	var subs = state.subscriptions;
	subs[index] = action.payload;
	return {...state, subscriptions: subs};
  case actions.RECEIVED_STORE_WISHLIST:
	return {...state, wishlist: action.payload};
  default:
	return state;
  }
}
