import React, {Component} from "react";
import {connect} from "react-redux";
import {Layout, Icon} from "antd";
import appActions from "../../redux/app/actions";
import TopbarUser from "./topbarUser";
import TopbarWrapper from "./topbar.style";

const {Header} = Layout;
const {toggleCollapsed} = appActions;

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.getStyling = this.getStyling.bind(this);
  }

  getStyling() {
    return {
      background: this.props.customizedTheme.backgroundColor,
      position: "fixed",
      width: "100%",
      height: 70,
    };
  }

  render() {
    const {toggleCollapsed} = this.props;
    const collapsed = this.props.collapsed && !this.props.openDrawer;
    return (
      <TopbarWrapper>
        <Header style={this.getStyling()} className={collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"}>
          <div className="isoLeft">
            <Icon type="menu" onClick={toggleCollapsed}
                  className={collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"}/>
          </div>
          <ul className="isoRight">
            <li onClick={() => this.setState({selectedItem: "notification"})} className="isoNotify">&nbsp;</li>
            <li onClick={() => this.setState({selectedItem: "user"})} className="isoUser">
              <TopbarUser/>
            </li>
          </ul>
        </Header>
      </TopbarWrapper>
    );
  }
}

export default connect(
  state => ({
    ...state.App,
    customizedTheme: state.ThemeSwitcher.topbarTheme
  }),
  {toggleCollapsed}
)(Topbar);
