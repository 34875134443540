import styled from 'styled-components';
import { palette } from 'styled-theme';
import { transition, borderRadius} from '../../settings/style-util';
import WithDirection from '../../settings/withDirection';

const TopbarWrapper = styled.div`
  .isomorphicTopbar {
    display: flex;
    justify-content: space-between;
    background-color: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: ${props =>
      props['data-rtl'] === 'rtl' ? '0 31px' : '0 31px'};
    z-index: 1000;
    ${transition()};
    .ant-menu-horizontal{
        border-bottom:none; 
        line-height:99px;
        border-bottom-width:0;
        li{
            border-bottom:none; 
        }
    }
#search .ant-input-search input {
	width: 15px;
	padding-left: 10px;
	color: transparent;
	cursor: pointer;
    border:none;
}
#search .ant-input-search input:hover {
	background-color: #fff;
border:1px solid #eee;
}
#search .ant-input-search input:focus {
	width: 200px;
	padding-left: 10px;
	color: #000;
	background-color: #fff;
	cursor: auto;
    border:1px solid #eee;
}
#search .ant-input-search input:-moz-placeholder {
	color: transparent;
}
#search .ant-input-search input::-webkit-input-placeholder {
	color: transparent;
border:1px solid #eee;
}
    .logo-wrapper{
        height:99px;
        display:flex;
        max-width:300px;
        img{
            object-fit:contain;
            height: 100%;
            object-fit: contain;
            max-width: 100%;
             @media only screen and (max-width: 765px) {
                display:block;
            }
        }
         @media only screen and (max-width: 765px) {
             height:50px;
        }
    }
.mobilemenu{
        position: absolute;
        left: 100vw;
        top: 99px;
        background: white;
        height: calc(100vh);
        transition: .5s;
        width:100vw;
            border-left: 1px solid #eee;
        @media only screen and (min-width: 700px) {
            max-width:450px;
        }
        @media only screen and (max-width: 765px) {
            top: 49px;
        }
        .ant-menu-submenu, .ant-menu-item , .user-menu li{
             width:100%;
            line-height: 25px;
           
            height: auto;
            
        }
        .user-menu{
                height: auto;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                padding-left:0;
                flex-direction:column;
            .isoUser{
                flex:1;
                text-align: left;
                padding: 10px 0 20px 15px;
                a{
                    font-size: 16px;
                    font-weight:500;
                }
            }
            .isoCart{
                flex:1;
                padding-left:7px;
                padding-top:5px;
            }
            li{
                a{
                    color:#222;
                }
                a:hover{
                    color:rgba(0, 0, 0, 0.65);
                }
            }
        }
        .ant-menu-submenu-active{
            color:rgba(0, 0, 0, 0.65);
        }
        .ant-menu-submenu .ant-menu-submenu-title{
            color:#222;
            background-color:transparent;
        }
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
            background-color:transparent;
            > a{
                color:rgba(0, 0, 0, 0.65);
            }
        }
        .ant-menu-item-active{
            background-color:#DDDDDD!important;
        }
        .ant-menu-item > a:hover{
            color:rgba(0, 0, 0, 0.65);
        }   
        .ant-menu-inline .ant-menu-item:after{
            border-right:0;
        }
        .search-bar{
            padding: 0 20px;
        }
    }
    .mobilemenu.menuOpen{
        height: 100vh;
        overflow-y: scroll;
        transform: translateX(0);
        left:0;
        @media only screen and (min-width: 700px) {
            left:calc(100vw - 450px);
        }
    }
.menu-mobile-wrapper{
     width:100%;
     display: flex;
      align-items: center;
    .mobile-menu-btn{
        padding-right:20px;
        display: flex;
         .triggerBtn {
            width: 24px;
            height: 100%;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            border: 0;
            outline: 0;
            position: relative;
            cursor: pointer;

            &:before {
              font-size: 26px;
              color: inherit;
              line-height: 0;
              position: absolute;
            }
          }
    }
    .logo-wrapper{
      margin: 0 auto !important;
    }
    .cart-btn-mobile{
        display: flex;
        margin-left: auto;
        padding-left:20px;
        .user-menu{
            height:58px;
        }
    }
}
.isoRight {
      display: flex;
      align-items: center;
      
    .triggerBtn {
        width: 24px;
        height: 100%;
        display: -webkit-inline-flex;
        display: -ms-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 0;
        outline: 0;
        position: relative;
        cursor: pointer;

        &:before {
          font-size: 26px;
          color: inherit;
          line-height: 0;
          position: absolute;
        }
      }
      li {
        margin-left: ${props => (props['data-rtl'] === 'rtl' ? '35px' : '0')};
        margin-right: ${props => (props['data-rtl'] === 'rtl' ? '0' : '35px')};
        cursor: pointer;
        line-height: normal;
        position: relative;
        display: inline-block;

        @media only screen and (max-width: 360px) {
          margin-left: ${props => (props['data-rtl'] === 'rtl' ? '25px' : '0')};
          margin-right: ${props =>
            props['data-rtl'] === 'rtl' ? '0' : '25px'};
        }

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 18px;
          color: ${palette('text', 0)};
        }

        .isoIconWrapper {
          position: relative;
          line-height: normal;

          span {
            font-size: 12px;
            color: #fff;
            background-color: ${palette('secondary', 1)};
            width: 20px;
            height: 20px;
            display: -webkit-inline-flex;
            display: -ms-inline-flex;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            line-height: 20px;
            position: absolute;
            top: -8px;
            left: ${props =>
              props['data-rtl'] === 'rtl' ? 'inherit' : '10px'};
            right: ${props =>
              props['data-rtl'] === 'rtl' ? '10px' : 'inherit'};
            ${borderRadius('50%')};
          }
        }
        &.isoNotify {
          .isoIconWrapper {
            span {
              background-color: ${palette('primary', 2)};
            }
          }
        }
        &.isoCart {
          .isoIconWrapper {
            span {
              background-color: ${palette('color', 2)};
            }
          }
        }
        &.isoUser {
             .top-bar-user{
                    width:auto!important;
                }
          .isoImgWrapper {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            background-color: ${palette('grayscale', 9)};
            ${borderRadius('50%')};
            
            img {
              height: 100%;
              object-fit: cover;
            }

            .userActivity {
              width: 10px;
              height: 10px;
              display: block;
              background-color: ${palette('color', 3)};
              position: absolute;
              bottom: 0;
              right: 3px;
              border: 1px solid #ffffff;
              ${borderRadius('50%')};
            }
          }
            .top-bar-user{
                 display: block;
                width: 50px;
                text-transform: uppercase;
                color: #111;
                a{
                    color:#222; 
                    font-weight:500;
                }
                a:hover{
                    
                    color:rgba(0,0,0,0.65);  
                }
            }
        }
      }
    }

    .ant-menu-horizontal > .ant-menu-submenu{
        color:#222;
        .ant-menu-submenu-title:hover{
            color:rgba(0,0,0,0.65);
        }
    }
    .ant-menu-horizontal > .ant-menu-item{
        a{
            
          color:#222;
        }
        a:hover{
            color:rgba(0,0,0,0.65);
        }
    } 

    .ant-menu-item > a{
        color:#222;
    }
    .ant-menu-item > a:hover
            {
            color:rgba(0,0,0,0.65);
            }

    .ant-menu-submenu > .ant-menu {
        .ant-menu-item-selected{
            background-color:#ddd;
        }
        .ant-menu-item > a{
            color:rgba(0,0,0,0.65);
        }
    
    }

    .ant-menu-submenu.ant-menu-submenu-popup{
        .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
            background-color:#ddd;
        }
        .ant-menu-item-selected{
            color:#222;
        }

        .ant-menu-item > a:hover
            {
            color:rgba(0,0,0,0.65);
            }
    }
  }
    #item_0$Menu{
    background-color:red;
}
`;

export default WithDirection(TopbarWrapper);
