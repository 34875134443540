import actions from "./actions";

const initState = {idToken: null, user: null, orders: [], subscription: {}};

export default function authReducer(state = initState, action) {
  switch (action.type) {
  case actions.LOGIN_SUCCESS:
	return {
	  idToken: action.token,
	  user: action.user
	};
  case actions.LOGOUT:
	return initState;
  case actions.UPDATE_USER:
	return {
	  user: action.payload
	};
  case actions.RECEIVED_USER_ADDRESS:
	var user = Object.assign({}, state.user, action.payload);
	return {...state, user: user};
  default:
	return state;
  }
}
