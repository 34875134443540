import React, {Component} from 'react';
import TopbarCartWrapper from './singleCartModal.style';
import NoProductImage from '../../image/no-product-image.webp';
import {Icon} from 'antd';
import {productURL} from "../../helpers/utility";
import {FM} from "../../languageProvider/FMreactIntl";

export default class extends Component {
  render() {
	const {product_image, id, cancelQuantity, name, color_name, size_name} = this.props;
	return (
		<TopbarCartWrapper className="isoCartItems">
		  <div className="isoItemImage">
			<img alt="#" src={product_image ? product_image : NoProductImage}/>
		  </div>
		  <div className="isoCartDetails">
			<h3><a href={productURL(name, id.substr(0, id.indexOf('-')))}>{name}</a></h3>
			<p className="isoItemPriceQuantity">
			  <span>{FM("Text.Color", "Color")}: {color_name}</span>
			</p>
			<p className="isoItemPriceQuantity">
			  <span className="isoItemQuantity">{FM("Text.Size", "Size")}: {size_name}</span>
			</p>
		  </div>
		  <a className="isoItemRemove" onClick={() => cancelQuantity(id)} href="#!"><Icon type="close"/></a>
		</TopbarCartWrapper>
	);
  }
}
